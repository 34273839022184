.page-login {
	display: flex;
	align-items: center;
	height: 100vh;
	background: #f3f3f3;
	.login-section{
		padding: 28px 28px 28px 28px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 500px;
		margin: auto;
		box-shadow: 0 0 10px #ccc;
		background: #fff;
		border-radius: 8px;
		@media(max-width: 639px) {
			width: 100%;
			margin: 0 30px;
			padding: 30px 32px 6px;
		}
		@media(max-width: 375px) {
			margin: 0 20px;
			padding: 30px 15px 6px;
		}
		img.logo {
			width: 100%;
			max-width: 140px;
			@media(max-width: 767px) {
				max-width: 120px;
			}
		}
		.login-form {
			width: 100%;
			margin-top: 40px;
			.ant-form-item {
				padding-bottom: 0;
			    margin-bottom: 30px;
			}
			.ant-form-explain {
				position: absolute;
			}
			input {
				height: 50px !important;
				background: #EFEFEF;
				border: none;
				outline: none;
				padding-left: 35px;
				// padding: 10px 20px;
				border-radius: 8px;
				font-size: 20px;
				@media(max-width: 639px) {
					height: 40px;
				}
				&::placeholder {
					font-weight: 500;
					opacity: 1;
					font-size: 20px;
					@media(max-width: 639px) {
						font-size: 16px;
					}
				}
			}
			label {
				font-size: 20px;
				color: rgba(0, 0, 0, 0.65);
				text-transform: capitalize;
				@media(max-width: 639px) {
					font-size: 16px;
				}
			}
			.button-section {
				margin-bottom: 0px;
				button {
					width: 100%;
					height: 50px;
					font-size: 20px;
					text-transform: uppercase;
					font-weight: bold;
					border: none;
					outline: none;
					border-radius: 8px;
					border: solid 1px transparent;
					@media(max-width: 639px) {
						font-size: 20px;
						height: 40px;
						margin-bottom: 15px;
					}
					&:hover {
						box-shadow: 0 0 3px rgba(0,0,0,0.5);
					}
				}
			}
		}
		.signup_signin{
			margin-top: 15px;
		}
	}
}