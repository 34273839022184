.product {
	.ant-modal-content{
		width: fit-content;
	}
	&.custom_product {
		width: auto !important;
		.ant-modal-content {
			width: 820px;
			@media only screen and (max-width: 767px)  {
				width: auto;
			}
		}
	}
	&.order_editModal {
		width: auto !important;
		.ant-modal-content {
			width: 840px;
			margin: auto;
			@media only screen and (max-width: 991px)  {
				width: 95%;
			}
		}
		.ant-calendar-picker {
			@media only screen and (max-width: 400px)  {
				width: calc(100% - 25%);
			}
		}
		.product_listing {
		    margin-top: 0;
		    .leftSide {
		    	margin-right: 25px;
			    /*display: flex;
			    align-items: center;
				flex-wrap: wrap;
				justify-content: space-between;*/
			    .deliveryDate {
				    display: block;
				    width: 100%;
				    margin: 0 0 5px 0;
				}
				.rightsideTotal {
					font-weight: bold;
					font-size: 16px;
					@media only screen and (max-width: 991px)  {
						font-size: 14px;
					}
				}
			}
			.buttons_addSave {
				margin: 15px 0;
				text-align: right;
			    button {
					margin-bottom: 0 !important;
					margin-left: 7px;
				}
			}
			table {
				thead {
					tr {
						th {
							text-transform: capitalize;
						}
					}
				}
				tbody {
					tr {
						td {
							input {
								&:focus {
									border-color: #d9d9d9;
								}
								&:hover {
									border-color: #d9d9d9;
								}
							}
							span {
								&.quantity_num {
									margin: 0 10px;
								}
							}
							 button {
							 	&.quantity_btn {
							 		color: rgba(0, 0, 0, 0.55);
							 		i {
										font-size: 12px;
										color: rgba(0, 0, 0, 0.55);
									}
							 		span {
									    font-weight: 400;
									    font-size: 15px;
									}
							 	}
							 }
						}
					}
				}
			}
		}
	}
}
.editable-cell {
	position: relative;
  }
  
.editable-cell-value-wrap {
padding: 5px 12px;
cursor: pointer;
}
.filter-main-section {
	.filter-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 25px;
	}
}
.smallWidth {
	max-width: 200px;
	min-width: 200px;
	white-space: normal;
}
.product_listing.see_all_product_section {
	margin-top: 0;
	.tableHeaderRow {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #e6f7ff;
		padding: 8px 15px;
		margin-bottom: 25px;
		.tableColumn {
			display: flex;
			flex-direction: column;
			.tableColumnHeader {
				font-size: 12px;
				font-weight: 600;
			}

			.tableColumnValue {
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
	.downloadInvoiceButton {
		text-align: right;
		padding-bottom: 15px;
	}
	.date-discount-section {
		display: flex;
		.discountCodeInputWrapper {
			display: flex;
		}
		.deliveryDate {
			display: block;
			margin: 0 0 5px 0;
		}
	}
}