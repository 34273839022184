.home_page{
	.dashboard-count {
		margin-top: 60px;
		.gutter-row {
			@media(max-width: 1400px) {
				width: 50%;
				margin-bottom: 50px;
			}
			@media(max-width: 870px) {
				width: 100%;
			}
			.gutter-box {
				background: #fff;
				border: solid 1px #ccc;
				border-radius: 6px;
				padding: 30px;
				.layout {
					background: transparent;
					border-bottom: solid 1px #ccc;
				}
				.icon {
					background: transparent;
					margin: -50px 0 50px;
					width: auto !important;
					min-width: auto !important;
					max-width: none !important;
					flex: 0 !important;
					i {
						background: var(--primary-color);
						width: 80px;
						height: 80px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 30px;
						color: #fff;
						border-radius: 3px;
					}
				}
				.content {
					text-align: right;
					p {
						color: #999;
						margin: 0;
						font-size: 14px;
						margin-top: 0;
						margin-bottom: 0;
					}
					h3 {
						font-size: 1.825em;
						color: #3C4858;
					}
				}
				.description {
					display: flex;
					align-items: center;
					border-top: solid 1px #ccc;
					padding-top: 10px;
					i {
						margin-right: 10px;
						color: #999;
					}
					p {
						margin: 0;
						color: #999;
					}
				}
			}
		}
	}
}