.user_title {
	h1 {
	    margin: 20px 0;
	}
}
.user_listing {
	margin-top: 30px;
	.ant-spin-container {
		.ant-table {
			background: #fff;
		}
	}
	.ant-table-body {
		white-space: nowrap;
		overflow-x: auto;
		table {
			tbody {
				tr {
					td {
						a {
							color: var(--black-color);
							font-weight: 300;
							&:hover {
						    	color: var(--primary-color);
						    }
						}
						button {
						    background: transparent;
						    border-color: transparent;
						    box-shadow: none;
						    color: var(--black-color);
						    padding: 0;
							line-height: normal;
							height: auto;
						    &:after {
						    	display: none;
						    }
						    &:hover {
						    	color: var(--primary-color);
						    }
						    span {
						    	font-weight: 300;
						    }
						}
					}
				}
			}
		}
	}
	.ant-table-pagination {
		&.ant-pagination {
			float: none;
			text-align: center;
			margin-top: 24px;
		}
	}
}