ul{
	&.user_info {
		box-shadow: 0 0 0 1px rgba(14,19,24,.07), 0 2px 12px rgba(14,19,24,.2);
		background-color: var(--white-color);
		padding: 8px 0;
		margin-right: 5px;
		li {
			color: var(--black-pearl);
			padding: 9px 16px;
			&:hover{
				background : rgba(14,19,24,.07);
			}
		}
	}
}
.responsive_menu{
	@media (max-width: 767px){
		display: block;
		width: 100%;
		background: var(--white-color);
		height: auto;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
	}
}
button{
	&.toggle_menu{
		&.ant-btn-primary {
			position: absolute;
			top: 25px;
			right: 0;
			background: transparent;
			border: none;
			font-size: 20px;
			outline: none;
			box-shadow: none;
			z-index: 999;
			display: none;
			@media (max-width: 767px){
				display: block;
			}
			&:after{
				display: none;
			}
			i {
				line-height: 14px;	
				color: var(--black-pearl);
			}
		}
	}
}


.main_sidebar{
	min-width: 260px !important;
	flex: 0 0 260px !important;
	max-width: 260px !important;
	width: 260px !important;
	background: var(--white-color);
	border-right: none;
	padding-top: 20px;
	box-shadow: 1px 0px 6px #666;
	@media (max-width: 767px){
		display: none;
	}
	.sidebar_autoscroll {
		height: calc(100vh - 250px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.user_toggle{
		margin: 24px 12px 16px 20px;
		padding: 8px 12px;
		display: block;
		border-radius: 4px;
		transition: background-color .3s ease,color .2s ease-out;
		cursor: pointer;
		&:hover {
			background: rgba(14,19,24,.07);
		}
		&.ant-dropdown-open{
			background: rgba(14,19,24,.15);
		}
	}
	.user_details {
		display: flex;
		align-items: center;
		h1 {
			background: var(--primary-color);
			margin: 0;
			width: 40px;
			height: 40px;
			font-weight: 500;
			font-size: 21px;
			color: var(--white-color);
			border-radius: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.user_name {
			margin-left: 12px;
			margin-right: 8px;
			span {
				display: block;
				color: var(--black-pearl);
				font-size: 14px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 130px;
				text-transform: capitalize;
			}
			button {
				text-align: left;
				color: var(--egg-blue);
				font-size: 12px;
				text-decoration: underline;
				background: transparent;
				border: none;
				cursor: pointer;
				outline: none;
				padding: 0;
			}
		}
		i{
			&.anticon{
				&.anticon-down {
					position: absolute;
					right: 30px;
					color: var(--black-pearl);
				}
			}
		}
	}
	button {
		&.create_design {
			width: 100%;
			max-width: 216px;
			outline: none;
			margin: 0 12px 16px 32px;
			background: var(--egg-blue);
			border: 2px solid transparent;
			border-radius: 30px;
			cursor: pointer;
			padding: 0 6px;
			height: 40px;
			text-align: center;
			left: .01rem;
			color: var(--white-color);
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			font-weight: 600;
			font-size: 14px;
			transition: background-color .1s linear,border-color .1s linear,color .1s linear;
			&:hover{
				background: var(--bright-turquoise);
			}
		}
	}
	.siderbar_menu{
		border-right: none;
		background: transparent;
		.ant-menu-submenu {
			.ant-menu-submenu-title{
				padding-left: 34px !important;
				text-transform: uppercase;
				border-left: solid 2px transparent;
				height: auto;
				line-height: normal;
				margin: 0;
				padding: 15px 0;
				&:hover {
					background: #e6f7ff;
					color: var(--primary-color);
					border-color: var(--primary-pearl);
					font-weight: 400;
				}
				&.active {
					background: #e6f7ff;
					color: var(--primary-color);
					border-color: var(--primary-pearl);
					font-weight: 400;
				}
			}
			.ant-menu-sub {
				li {
					padding-left: 0 !important;
					height: auto;
					line-height: normal;
					a {
						padding-left: 64px;
					}
				}
			}
		}
		li {
			cursor: pointer;
			padding: 0px !important;
			margin: 0 !important;
			font-size: 14px;
			font-weight: 400;
			color: var(--black-pearl);
			height: auto;
			line-height: normal;
			&.ant-menu-item{
				&:active {
					background: transparent;
				}
			}
			&.ant-menu-submenu-title{
				&:active {
					background: transparent;
				}
			}
			&.ant-menu-item-selected {
				background-color: transparent;
				/*display: none;*/
				&:after {
					opacity: 0;
				}
			}
			a {
				width: 100%;
				display: block;
				color: var(--black-color);
				padding: 15px 32px;
				transition: background-color .15s ease-in-out,opacity .15s ease-in-out;
				font-size: 14px;
				text-transform: uppercase;
				border-left: solid 10px transparent;
				border-left: solid 2px transparent;
				font-weight: 400;
				&:hover {
					background: #e6f7ff;
					color: var(--primary-color);
					border-color: var(--primary-pearl);
					font-weight: 400;
				}
				&.active {
					background: #e6f7ff;
					color: var(--primary-color);
					border-color: var(--primary-pearl);
					font-weight: 400;
				}
			}
			i {
				min-width: 16px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
	}
	p {
		color: rgba(14,19,24,.45);
		font-size: 12px;
		padding-left: 32px;
		font-weight: 600;
		margin-top: 24px;
		margin-bottom: 0;
	}
}


.sidebar_drawer_menu{
	.sidebar_autoscroll {
		height: calc(100vh - 56px);
		padding-right: 0;
		overflow: hidden;
		overflow-y: auto;
	}
	.ant-drawer-wrapper-body{
		overflow: hidden !important;
	}
	.ant-drawer-body{
		padding: 0;
	}
	.user_toggle{
		margin: 8px 12px 6px;
		padding: 8px 12px;
		display: block;
		border-radius: 4px;
		transition: background-color .3s ease,color .2s ease-out;
		cursor: pointer;
		&:hover {
			background: rgba(14,19,24,.07);
		}
		&.ant-dropdown-open{
			background: rgba(14,19,24,.15);
		}
	}
	.user_details {
		display: flex;
		align-items: center;
		h1 {
			background: var(--primary-color);
			margin: 0;
			width: 40px;
			height: 40px;
			font-weight: 500;
			font-size: 21px;
			color: var(--white-color);
			border-radius: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.user_name {
			margin-left: 12px;
			margin-right: 8px;
			span {
				display: block;
				color: var(--black-pearl);
				font-size: 14px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 130px;
				text-transform: capitalize;
			}
			button {
				text-align: left;
				color: var(--egg-blue);
				font-size: 12px;
				text-decoration: underline;
				background: transparent;
				border: none;
				cursor: pointer;
				outline: none;
				padding: 0;
			}
		}
		i{
			&.anticon{
				&.anticon-down {
					position: absolute;
					right: 20px;
					color: var(--black-pearl);
				}
			}
		}
	}
	button {
		&.create_design {
			width: 100%;
			max-width: 216px;
			outline: none;
			margin: 0 12px 16px 20px;
			background: var(--egg-blue);
			border: 2px solid transparent;
			border-radius: 30px;
			cursor: pointer;
			padding: 0 6px;
			height: 40px;
			text-align: center;
			left: .01rem;
			color: var(--white-color);
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			font-weight: 600;
			font-size: 14px;
			transition: background-color .1s linear,border-color .1s linear,color .1s linear;
			&:hover{
				background: var(--bright-turquoise);
			}
		}
	}
	.siderbar_menu{
		border-right: none;
		li {
			cursor: pointer;
			padding: 0px !important;
			margin: 0 !important;
			font-size: 14px;
			font-weight: 400;
			color: var(--black-pearl);
			height: 45px;
			line-height: 45px;
			&.ant-menu-item-selected {
				background-color: transparent;
				/*font-weight: 700;*/
				&:after {
					opacity: 0;
				}
			}
			a {
				width: 100%;
				display: block;
				color: var(--black-pearl);
				padding: 0 28px;
				transition: background-color .15s ease-in-out,opacity .15s ease-in-out;
				font-size: 14px;
				text-transform: uppercase;
				font-weight: 400;
				&:hover {
					background: #e6f7ff;
					border-radius: 4px;
					font-weight: 400;
					color: var(--primary-color);
				}
				&.active {
					background: #e6f7ff;
					color: var(--primary-color);
					font-weight: 400;
				}
			}
			i {
				min-width: 16px;
				margin-right: 20px;
				font-size: 16px;
			}
			&:after {
				border-right: none !important;
			}
		}
	}
	p {
		color: rgba(14,19,24,.45);
		font-size: 12px;
		padding-left: 32px;
		font-weight: 600;
		margin-top: 24px;
		margin-bottom: 0;
	}
}

@supports (-webkit-overflow-scrolling: touch) {
	.sidebar_drawer_menu .sidebar_autoscroll{
		height: calc(100vh - 310px);
	}
}