.customers_title {
	h1 {
	    margin: 20px 0;
	}
}
.customer_search {
	.ant-input-search {
		&:hover {
			.ant-input:not(.ant-input-disabled) {
				border-color: var(--dark-grey);
				box-shadow: none;
			}
		}
	}
	input {
	    height: auto;
	    padding: 15px 50px 15px 25px !important;
	    border-radius: 100px;
	    font-size: 18px;
	    border: 1px solid var(--dark-grey);
	    @media (max-width: 991px){
			padding: 10px 50px 10px 25px !important;
		}
		&:hover {
			border-color: var(--dark-grey);
			box-shadow: none;
		}
		&:focus {
			border-color: var(--dark-grey);
			box-shadow: none;
		}
	}
	.ant-input-affix-wrapper {
		.ant-input-suffix {
			right: 20px;
		}
	}
	i {
		font-size: 22px;
	}
}
.main_customers_list {
	margin-top: 30px;
	ul.ant-list-items {
		background: var(--white-color);
		padding: 15px 15px 0 15px;
	    li {
	    	&.customer_details {
	    		&:last-child {
				    border-bottom: none !important;
				}
	    		.customer_info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					span {
					    width: 100%;
					    display: block;
					    font-weight: bold;
					}
					a {
						color: var(--black-color);
						word-break: break-all;
						margin-right: 10px;
						line-height: normal;
						font-weight: 300;
						@media (max-width: 500px){
							display: block;
						}
						&:hover {
							color: var(--primary-color);
						}
					}
					.customer_name {
						width: calc(100% - 17%);
						.phone_email {
							display: flex;
							flex-wrap: wrap;
							margin-top: 2px;
							.email {
							    display: flex;
								align-items: center;
								margin-top: 2px;
							}
							.number {
								display: flex;
								align-items: center;
								margin-top: 2px;
							}
							i {
							    margin-right: 5px;
							}
						}
						.dicountData {
							margin-top: 4px;
							span {
								display: unset;
								margin-right: 5px;
							}
						}
					}
					.customer_button {
						display: flex;
						align-items: center;
						text-align: right;
						padding-left: 15px;
						@media (max-width: 500px){
							padding-left: 10px;
						}
						button {
						    background: transparent;
						    border-color: transparent;
						    box-shadow: none;
						    color: var(--black-color);
						    padding: 0;
							line-height: normal;
							height: auto;
						    &:after {
						    	display: none;
						    }
						    &:hover {
						    	color: var(--primary-color);
						    }
						    span {
						    	font-weight: 300;
						    }
						}
					}
					.Discount_fields {
					    margin-bottom: 15px;
					    width: 100%;
					    display: flex;
					    justify-content: flex-end;
					    .type_amount {
					    	flex-wrap: unset;
					    	span {
					    		font-size: 13px;
					    	}
					    	.discount_amount {
					    		.ant-form-explain {
								    font-size: 12px !important;
								}
					    	}
					    }
					    .buttons {
					    	margin-right: 0;
					    	span {
					    		&.hidden {
					    			visibility: hidden;
					    		}
					    	}
					    	button {
							    margin: 0 15px 0 0;
							    &:last-child {
								    margin-right: 0;
								}
							}
					    }
					}
					.icon_btn {
						> div {
						    display: flex;
						}
						button {
						    margin-left: 15px;
						}
					}
		    	}
	    	}
	    }
	}
	.ant-list-pagination {
		display: flex;
		justify-content: center;
	}
}