body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main-layout {
	.ant-layout-content {
		background-color: var(--white-color);
		height: auto;
		.main {
			width: 100%;
			padding-top: 79px;
		}
		.page_content {
			overflow: auto;
			height: calc(100vh - 79px);
			padding: 32px 60px 32px 60px;
			margin-left: 260px;
			position: relative;
			z-index: 1;
			background: var(--light-background);
			border-left: 1px solid var(--light-grey);
			/*box-shadow: inset 7px 0 7px -7px rgba(0,0,0,0.9);
			-webkit-animation: fadein 1s; 
			-moz-animation: fadein 1s; 
			-ms-animation: fadein 1s;
			-o-animation: fadein 1s;
			animation: fadein 1s;*/
			@media (max-width: 991px){
				padding: 32px 30px;
			}
			@media (max-width: 767px){
				padding: 32px 15px;
				margin-left: 0;
				box-shadow: none;
			}
			.page-title {
				font-size: 26px;
				/*font-weight: bold;*/
				margin-bottom: 20px;
				display: inline-flex;
				text-transform: capitalize;
			}
			.ant-table-body {
				width: 100%;
				overflow: auto;
			}
		}
	}
}

/* Capitalize first letter of antd error modal*/
.error-dialog {
	.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content:first-letter {
		text-transform: capitalize
	}
}

/* Disable selection */
@mixin disable-selection {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Safari */
	-khtml-user-select: none;    /* Konqueror HTML */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;           /* Non-prefixed version, currently supported by Chrome and Opera */
}

/* No selectable element */
.no-selectable {
	@include disable-selection;
}

@keyframes fadein {
	from { opacity: 0; }
	to { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; }
	to { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

:root{
	--primary-color: #1890ff;
	--primary-shade-color: #9c5569;
	--secondary-color: #364d79;
	--white-color: #ffffff;
	--black-color: #000000;
	--black-pearl: #0e1318; 
	--egg-blue: #00c4cc;
	--bright-turquoise: #00d9e1;
	--light-grey: #eeeeee;
	--box-shadow-color: #ccc;
	--light-background: #f7f7f7;
	--dark-grey: #D3D3D3;
}