.product_title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	h1 {
	    margin: 20px 0;
	}
}
.product_search {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	.dropdown-container {
		background: #fff;
		width: 100%;
		border-radius: 30px;
		padding: 8px;
		border: 1px solid var(--dark-grey);
		.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active{
			box-shadow: none
		}
	  }
	.ant-select-lg .ant-select-selection--single {
		border-radius:20px;
		border: none;
		.ant-select-arrow{
			top: 40%;
			font-size: 10px !important;
			color: rgba(0, 0, 0, 0.45);
		}
	  }
	.ant-input-search {
		&:hover {
			.ant-input:not(.ant-input-disabled) {
				border-color: var(--dark-grey);
				box-shadow: none;
			}
		}
	}
	input {
	    height: auto;
	    padding: 15px 50px 15px 25px !important;
	    border-radius: 100px;
	    font-size: 18px;
	    border: 1px solid var(--dark-grey);
	    @media (max-width: 991px){
			padding: 10px 50px 10px 25px !important;
		}
		&:hover {
			border-color: var(--dark-grey);
			box-shadow: none;
		}
		&:focus {
			border-color: var(--dark-grey);
			box-shadow: none;
		}
	}
	.ant-input-affix-wrapper {
		.ant-input-suffix {
			right: 20px;
		}
	}
	i {
		font-size: 22px;
	}
}

.product_listing {
	margin-top: 30px;
	.ant-spin-container {
		.ant-table {
			background: #fff;
		}
	}
	.ant-table-body {
		white-space: nowrap;
		overflow-x: auto;
		table {
			tbody {
				tr {
					td {
						img {
							max-width: 40px;
						}
						button {
						    background: transparent;
						    border-color: transparent;
						    box-shadow: none;
						    color: var(--black-color);
						    padding: 0;
							line-height: normal;
							height: auto;
						    &:after {
						    	display: none;
						    }
						    &:hover {
						    	color: var(--primary-color);
						    }
						    span {
						    	font-weight: 300;
						    }
						}
					}
				}
			}
		}
	}
	.ant-table-pagination {
		&.ant-pagination {
			float: none;
			text-align: center;
			margin-top: 24px;
		}
	}
}

.edit_product {
	.ant-modal-body {
		form {
			.ant-upload {
				width: 100%;
				button {
					width: 100%;
					text-align: left;
					padding: 0 11px;
					&:hover {
						color: inherit;
					}
					&:focus {
						color: inherit;
					}
				}
			}
			.ant-upload-list-picture .ant-upload-list-item {
				height: 50px;
				border-color: #1990ff;
			}
			.ant-upload-list-picture .ant-upload-list-item-name {
				line-height: 28px;
				color: #1990ff;
			}
			.ant-upload-list-item-card-actions.picture {
				top: 18px;
			}
			.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
				height: 32px;
				width: 32px;
			}
			.ant-upload-list-picture .ant-upload-list-item-thumbnail {
				width: 32px;
				height: 32px;
			}
		}
	}
}
.add_product {
	.ant-modal-body {
		form {
			.ant-upload {
				width: 100%;
				button {
					&:hover {
						color: inherit;
					}
					&:focus {
						color: inherit;
					}
					span {
						opacity: 0.4;
					}
				}
			}
		}
	}
}
.ant-tooltip{
    visibility: hidden;
}
.subCategories {
    margin-bottom: 20px;
    border: 1px solid #d9d9d9;
    padding: 5px;
    border-radius: 3px;
}