.discount_table {
	.ant-table-pagination.ant-pagination {
	    float: none;
	    margin: 24px 0;
	    text-align: center;
	}
}
.Discount_fields {
	margin-bottom: 15px;
	.ant-row.ant-form-item {
	    margin: 0;
	}
	.ant-form-item-control {
		line-height: normal;
	}
	.has-error {
		.ant-form-explain {
		    font-size: 12px;
		}
	}
	form {
		display: flex;
		flex-wrap: wrap;
	}
    input {
	    width: 100%;
	    max-width: 150px;
		border: 1px solid var(--dark-grey);
		margin-bottom: 5px;
	    &:focus {
	    	box-shadow: none;
	    	border-color: var(--dark-grey);
	    }
	}
    .discountcode_generatecode {
	    .generate_code {
			margin-top: -1px;
			margin-left: 15px;
	    }
	    .discount_code {
		    width: auto;
		    display: flex;
		    margin-top: 5px;
		}
	}
	.type_amount {
		display: flex;
		flex-wrap: wrap;
		.discount_type {
			margin: 0 15px;
			.inner_type {
				display: flex;
				margin-top: 10px;
			}
			button {
				&.ant-switch {
				    margin: 0 5px;
					background: #1890ff;
				}
			}
		}
		.discount_amount {
			margin-right: 15px;
			[name="discountAmount"] {
				margin-top: 5px;
			}
		}
	}
	.buttons {
		margin-right: 15px;
		span.hidden {
			visibility: hidden;
			display: block;
			margin-bottom: 5px;
		}
		button {
		    margin: 0 15px 15px 0;
		}
	}
}