.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: var(--white-color);
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px 0px 0px;
	box-shadow: 0 2px 8px #f0f1f2;
	height: 79px;
	@media (max-width: 767px) {
		padding: 0 20px;
	}
	.logo {
		width: 260px;
		background: #fff !important;
		height: 100%;
		text-align: center;
		margin: 0;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 767px) {
			background: transparent !important;
			box-shadow: none;
			justify-content: start;
		}
		img {
			display: block;
			max-width: 110px;
			@media (max-width: 767px) {
				max-width: 90px;
			}
		}
	}
	.user_dropdown {
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0 0 0 20px;
		@media (max-width: 767px) {
			display: none;
		}
		.user_toggle{
			margin: 0;
			padding: 0;
			padding: 8px 12px;
			display: block;
			border-radius: 4px;
			transition: background-color .3s ease,color .2s ease-out;
			cursor: pointer;
			&:hover {
				background: rgba(14,19,24,.07);
			}
			&.ant-dropdown-open{
				background: rgba(14,19,24,.15);
			}
		}
		.user_details {
			display: flex;
			align-items: center;
			h1 {
				background: var(--primary-color);
				margin: 0;
				width: 40px;
				height: 40px;
				font-weight: 500;
				font-size: 21px;
				color: var(--white-color);
				border-radius: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.user_name {
				margin-left: 12px;
				margin-right: 8px;
				span {
					display: block;
					color: var(--black-pearl);
					font-size: 14px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					max-width: 130px;
					text-transform: capitalize;
				}
				button {
					text-align: left;
					color: var(--egg-blue);
					font-size: 12px;
					text-decoration: underline;
					background: transparent;
					border: none;
					cursor: pointer;
					outline: none;
					padding: 0;
				}
			}
			i{
				&.anticon{
					&.anticon-down {
						color: var(--black-pearl);
					}
				}
			}
		}
	}
}