.not-found {
	display: flex;
	align-items: center;
	height: 100vh;
	background: #1DA57A;
	text-align: center;
	width: 100%;
	.section {
		width: 100%;
		h1.code {
			font-size: 144px;
			color: white;
			width: 100%;
			text-align: center;
			margin-bottom: 0px;
			font-family: "Open Sans", sans-serif;
		}
		hr {
			padding: 0;
			border: none;
			border-top: 5px solid #fff;
			color: #fff;
			text-align: center;
			margin: 0px auto;
			width: 340px;
			height: 10px;
			z-index: -10;
		}
		a {
			font-family: "Open Sans", sans-serif;
			color: #1DA57A;
			font-size: 16px;
			text-transform: uppercase;
			letter-spacing: 1px;
			width: 288px;
			padding: 5px;
			font-weight: bold;
			margin: 20px auto;
			background-color: white;
			position: relative;
			display: inline-block;
		}
	}
}