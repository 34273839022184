.category_title {
	display: flex;
    justify-content: space-between;
    align-items: center;
	h1 {
	    margin: 20px 0;
    }
}
.main_category_list {
	ul.ant-list-items {
		background: var(--white-color);
		padding: 15px 15px 0 15px;
	    li {
	    	&.category_details {
	    		&:last-child {
				    border-bottom: none !important;
				}
	    		.category_info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					span {
					    width: 100%;
					    display: block;
					    font-weight: bold;
					}
					a {
						color: var(--black-color);
						word-break: break-all;
						&:hover {
							color: var(--primary-color);
						}
					}
					.category_name {
						width: calc(100% - 17%);
					}
					.category_button {
						display: flex;
						align-items: center;
						text-align: right;
						padding-left: 15px;
						@media (max-width: 500px){
							padding-left: 10px;
						}
						button {
						    background: transparent;
						    border-color: transparent;
						    box-shadow: none;
						    color: var(--black-color);
						    padding: 0;
							line-height: normal;
							height: auto;
						    &:after {
						    	display: none;
						    }
						    &:hover {
						    	color: var(--primary-color);
						    }
						    span {
						    	font-weight: 300;
						    }
						}
					}
		    	}
	    	}
	    }
	}
	.ant-list-pagination {
		display: flex;
		justify-content: center;
	}
}